import React from 'react';
const classname = "youtube-contain";

const content = `
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11745.51077128428!2d-82.8898361!3d42.6109452!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x81662065259bbfe3!2sThe%20Spider%20Guy!5e0!3m2!1sen!2sus!4v1617899216886!5m2!1sen!2sus" width="400" height="250" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
        `

const Mapembed = () =>  (
    
        
    <div className="columns is-multiline">
        <div className={classname} dangerouslySetInnerHTML={{ __html: content }} />
            <div className="addressblock">
                <div className="addressinner">289 N Wilson Blvd, Mt Clemens, MI 48043</div>
                <div className="addressphone">
                    <a href="tel:586-463-1280">586-463-1280</a>
                </div>
            </div>
    </div>    
    
)

export default Mapembed;