import React from 'react'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'


const RequestCall = () => (
    <div className="columns is-multiline is-0 cta">
        <div className="is-parent column is-14">
            Request a call and one of our skilled techinicians will contact you as soon as possible.
           
        </div>  
        <div className="is-parent column is-2">
         <a href="/contact" className="button is-success is-rounded">Request A Call</a>  
         </div>
    </div>
)

export default RequestCall;