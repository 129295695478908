import React from 'react'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import './all.sass'

const Offer = ({title, tag, price}) => (
    <div className="column is-multiline">
    <div className="offer">
        <div className="offertitle">
            {title}
        </div>
        <div className="offertag">
            {tag}
           
        </div>  
        
         <div className="is-parent column is-3 ctabutton">
         {price}
         <a href="/receive-a-call" className="button is-success is-rounded">Request A Call</a> 
          
         </div>
    </div>
    </div> 
    
)

export default Offer;