import React from 'react'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Img from 'gatsby-image'
import './all.sass'

const SuperHero = ({image, title, subheading}) => (
    <div> 
        
        <div
            className="third-width-image margin-top-0"
            style={{
                backgroundImage: `url(${
                !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                })`,
                backgroundPosition: `top right`,
                backgroundAttachment: `inherit`,
            }}
        ></div>
      

        <div style={{width: `66%`, height: `400px`, float: 'right', marginTop: '-250px', marginBottom: '-250px'}}>
            <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
                color: '#6e3f95',
                lineHeight: '1',
                padding: '0.25em',
            }}
            >
            {title}
            </h1>
            <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
                color: '#6e3f95',
                lineHeight: '1',
                padding: '0.25em',
            }}
            >
            {subheading}
            </h3>
        </div>
    </div>
)

export default SuperHero;