import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  
  <div className="columns is-multiline">
    {gridItems.map(item => (
      <article className="blog-list-item tile is-child box notification">
      <div key={item.text} className="column is-6 service">
        <a href={item.svcurl} className="featureclick">
        <section className="section">
          <div className="has-text-centered">
            <div
              style={{
                width: '240px',
                display: 'inline-block',
              }}
            >
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
          <div className="servicetitle">{item.servicetitle}</div>
          <div className="servicetagline">{item.servicetagline}</div>
          <div className="servicetext">{item.text}</div>
        </section>
        </a>
      </div>
      </article>
    ))}
   </div>
 
  
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
