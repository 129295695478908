import React from 'react'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import MapOc from './MapOc'
import MapMc from './MapMc'
import './all.sass'

const Locations = () => (
    <div>
        <div className="locationtitle">
            LOCATIONS
        </div>
        <div className="locationtag">
            The Spider Guy has locations in Oakland County and Macomb County.
        </div>
        <div className="columns is-multiline is-1 locations">
            <div className="is-parent column is-6 location">
                <a href="/oakland-county-spider-control-services">
                    <div className="locationstate">Oakland County</div>
                    <div className="column is-6 center"><MapOc /></div>
                </a>
                <div className="locationphone">
                    
                </div>
            </div>
            <div className="is-parent column is-6 location">
                <a href="/macomb-county-spider-control-services">
                    <div className="locationstate">Macomb County</div>
                    <div className="column is-6 center"><MapMc /></div>
                </a>
                <div className="locationphone">
                    
                </div>
            </div>  

        </div>
    </div>
)

export default Locations;