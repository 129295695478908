import React from 'react'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import './all.sass'

const CallToAction = () => (
    
    <div className="columns is-multiline is-0 cta">
        <div className="is-parent column is-3 ctabutton">
            Make One Call to Control Spiders and Exterior Pests.
        </div>
        <div className="is-parent column is-3 ctabutton">
        <a href="/services" className="button is-success is-rounded">What Does The 
        Spider Guy Do?</a>  
           
        </div>  
        <div className="is-parent column is-3 ctabutton">
         <a href="/get-a-quote" className="button is-success is-rounded">Request A Quote Now</a>  
         </div>
         <div className="is-parent column is-3 ctabutton">
         <a href="/receive-a-call" className="button is-success is-rounded">Request A Call Back</a>  
         </div>
    </div>
    
)

export default CallToAction;