import React from 'react';
const classname = "youtube-contain";

const content = `
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.7126999440165!2d-83.16795344818522!3d42.64625027906646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824c1f72a2199cf%3A0x637a6cf10b1517d0!2sNexGreen%20Lawn%2C%20Tree%20and%20Exterior%20Pest%20Control!5e0!3m2!1sen!2sus!4v1617899357294!5m2!1sen!2sus" width="400" height="250" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
        `

const Mapembed = () =>  (
    
        
    <div className="columns is-multiline">
        <div className={classname} dangerouslySetInnerHTML={{ __html: content }} />
            <div className="addressblock">
                <div className="addressinner">1653 Star-Batt Dr, Rochester Hills, MI 48309</div>
                <div className="addressphone">
                    <a href="tel:248-918-5160">248-918-5160</a>
                </div>
            </div>
    </div>    
    
)

export default Mapembed;



